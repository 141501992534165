const baseUrlocal = "https://pmsapi.xeventechnologies.com/";
// const baseUrlocal = "http://localhost:9000/";
const APP_SETTINGS = {
  API_PATH: {
    userLogin: baseUrlocal + "loginUser",
    // generateBlog: baseUrl + "/generate_blog_from_prompt",
    generateBlog: baseUrlocal + "getSearchData",
    user: baseUrlocal + "getUsersData",
    getUsersByLoginId: baseUrlocal + "getUsersByLoginId",
    addUser: baseUrlocal + "createNewUser",
    deleteUser: baseUrlocal + "delete",
    updateUser: baseUrlocal + "updateUser",
    getBlogsByUser: baseUrlocal + "get/blogByloginId",
    createTask: baseUrlocal + "createTask",
    createProject: baseUrlocal + "createProject",
    createTeam: baseUrlocal + "createTeam",
    getAllTeams: baseUrlocal + "getAllTeams",
    editTeam: baseUrlocal + "editTeam",
    editTask: baseUrlocal + "editTask",
    deleteTeam: baseUrlocal + "deleteTeam",
    editProject: baseUrlocal + "editProject",
    editSalleryResource: baseUrlocal + "editSalleryResource",
    deleteSalleryResource: baseUrlocal + "deleteSalleryResource",
    deleteProject: baseUrlocal + "deleteProject",
    getAllProject: baseUrlocal + "getAllProject",
    getAllTeamsByoginID: baseUrlocal + "getAllTeamsByLoginID",
    getAllgenerateBlogRequest: baseUrlocal + "getAllTasks",
    getGenerateBlogRequestByLoginId: baseUrlocal + "getTasksByLoginId",
    getTasksByProjectManager: baseUrlocal + "getTasksByProjectManager",
    blogChangestatus: baseUrlocal + "changeBlogStatus",
    deleterequest: baseUrlocal + "deleteResource",
    deletTask: baseUrlocal + "deletTask",
    createNewSalleryResource: baseUrlocal + "createNewSalleryResource",
    getAllSalleryResources: baseUrlocal + "getAllSalleryResources",
    projectProgressData: baseUrlocal + "projectProgressData",
    getTasksByTreamLeadResurces:
      baseUrlocal + "getCurrentDateTasksByTeamLeadResurces",
    getAllMyResourcesTasks: baseUrlocal + "getAllMyResourcesTasks",
    matchEncryptionKeyValue: baseUrlocal + "matchEncryptionKeyValue",
  },
};
export default APP_SETTINGS;
