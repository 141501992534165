import axios from "axios";
import APP_SETTINGS from "../../config";
export async function loginUserApi(data) {
const result= await axios.post(APP_SETTINGS.API_PATH.userLogin, data);
return result
}
export const getAllUserWithoutLoginIdApi = (req) => {
  return axios.post(APP_SETTINGS.API_PATH.user,req);
};
export const getAllUserApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.getUsersByLoginId,data);
};
export const addUserApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.addUser, data);
};
export const editUserApi = (data) => {
  return axios.put(`${APP_SETTINGS.API_PATH.updateUser}/${data?.login_id}`,data);
};
export const editTeamApi = (data) => {
  return axios.put(`${APP_SETTINGS.API_PATH.editTeam}/${data?.id}`,data);
};
export const deleteTeamApi = (data) => {
  return axios.delete(`${APP_SETTINGS.API_PATH.deleteTeam}/${data}`);
};
export const editProjectApi = (data) => {
  return axios.put(`${APP_SETTINGS.API_PATH.editProject}/${data?.id}`,data);
};

export const editNewSalleryResourceApi = (data) => {
  return axios.put(`${APP_SETTINGS.API_PATH.editSalleryResource}/${data?.id}`,data);
};
export const deleteProjectApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.deleteProject,data);
};
export const deleteSalleryResourceApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.deleteSalleryResource,data);
};
export const archiveUserApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.user, data);
};
export const deleteUserApi = (data) => {
  return axios.delete(`${APP_SETTINGS.API_PATH.deleteUser}/${data}`);
};
export const getBlogsByUserIdApi = (data,userData) => {
  return axios.post(APP_SETTINGS.API_PATH.user, data,{
    headers: {
      Authorization: "Bearer " + userData,
    },
  });
};
export const getGenerateBlogRequestByLoginIdApi = (data) => {
  return axios.post(`${APP_SETTINGS.API_PATH.getGenerateBlogRequestByLoginId}`,data);
};
export const getTasksByProjectManagerApi = (data) => {
  return axios.post(`${APP_SETTINGS.API_PATH.getTasksByProjectManager}`,data);
};
export const getAllgenerateBlogRequestApi = (data) => {
   
  return axios.post(APP_SETTINGS.API_PATH.getAllgenerateBlogRequest,data);
};
export const generateBlogRequestApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.createTask,data);
};
export const createProjectApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.createProject,data);
};
export const createTeamApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.createTeam,data);
};
export const getAllTeamsApi = (req) => {
  return axios.post(APP_SETTINGS.API_PATH.getAllTeams,req);
};
export const getAllTeamsByoginIDApi = (data) => {
   
  return axios.post(APP_SETTINGS.API_PATH.getAllTeamsByoginID,data);
};
export const getAllProjectApi = (req) => {
  return axios.post(APP_SETTINGS.API_PATH.getAllProject,req);
};
export function blogChangestatusApi(data) {
  return axios.put(`${APP_SETTINGS.API_PATH.blogChangestatus}/${data?.request_id}`, data);
}
export const deleterequestApi = (data) => {
  return axios.delete(
    `${APP_SETTINGS.API_PATH.deleterequest}/${data?.requestId}`
  );
};
export const deletTaskApi = (data) => {
  return axios.delete(
    `${APP_SETTINGS.API_PATH.deletTask}/${data}`
  );
};
export const createNewSalleryResourceApi = (data) => {
  return axios.post(APP_SETTINGS.API_PATH.createNewSalleryResource, data);
};
export const getAllSalleryResourcesApi = (req) => {
  return axios.post(APP_SETTINGS.API_PATH.getAllSalleryResources,req);
};
export const projectProgressDataApi = (req) => {
  return axios.post(APP_SETTINGS.API_PATH.projectProgressData,req);
};
export const getTasksByTreamLeadResurcesApi = (req) => {
  return axios.post(APP_SETTINGS.API_PATH.getTasksByTreamLeadResurces,req);
};
export const editTaskApi = (data) => {
  return axios.put(`${APP_SETTINGS.API_PATH.editTask}/${data?.id}`,data);
};
export const getAllMyResourcerTaskApi = (data) => {
  return axios.post(`${APP_SETTINGS.API_PATH.getAllMyResourcesTasks}`,data);
};
export const matchEncryptionKeyValueApi = (data) => {
  
  return axios.post(`${APP_SETTINGS.API_PATH.matchEncryptionKeyValue}`,data);
};