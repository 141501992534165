import { Link, useLocation } from "react-router-dom";
import "../../assets/js/jquerry.slimscroll";
// import { useState } from "react";
// import * as FaIcons from "react-icons/fa";
// import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { MdDashboardCustomize, MdOutlineSpaceDashboard } from "react-icons/md";
import { HiUsers } from "react-icons/hi";

import IMAGES from "../../assets/images";
import { PATH } from "../../utils/path.jsx";
import { FaBars, FaBloggerB } from "react-icons/fa";
import { useAuth } from "../../authorization/ProvidedAuth";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

export default function Sidebar() {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem("hea_user"));
  let location = useLocation();
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <div className="header-left mb-2 d-flex pageName">
            <Link
              to=""
              className="logo mr-0 header-logo-image text-decoration-none"
            >
              <img
                alt="ajhgs"
                src={IMAGES.SIDE_MANUE_LOGO}
                className="sidebar-log-img"
              />{" "}
            </Link>
            <a href="javascript:void(0)" id="toggle_btn">
              <FaBars className="toggleset" />
            </a>
          </div>
          <ul>
            <>
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.DASHBOARD ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Dashboard</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.USER ||
                    location.pathname === PATH.GENERATE_BLOG ||
                    location.pathname.includes("/resource-tasks")
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.USER,
                    }}
                    className=""
                  >
                    {" "}
                    <HiUsers className="" />
                    <span>Resources Settings</span>
                  </Link>
                </li>
              )}
              {/* {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.BLOG_REQUEST || location.pathname.includes("/resource-tasks") ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.BLOG_REQUEST,
                    }}
                    className=""
                  >
                    {" "}
                    <FaBloggerB className="" />
                    <span>Total Resources</span>
                  </Link>
                </li>
              )} */}
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.MY_BLOG ||
                    location.pathname === "/project-progress"
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.MY_BLOG,
                    }}
                    className=""
                  >
                    {" "}
                    <MdOutlineSpaceDashboard className="" />
                    <span>Projects</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.RESOURCE_DASHBOARD
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.RESOURCE_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Tasks List</span>
                  </Link>
                </li>
              )}

              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.GENERATE_TEAM ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.GENERATE_TEAM,
                    }}
                    className=""
                  >
                    {" "}
                    <AiOutlineUsergroupAdd className="" />
                    <span>Project Teams</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.SALLERY_MANAGEMENT
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.SALLERY_MANAGEMENT,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Salary Management</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.RESOURCE_SALLERY_SETTINGS
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.RESOURCE_SALLERY_SETTINGS,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Settings</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.PROJECT_COST_ESTIMATION
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.PROJECT_COST_ESTIMATION,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Project Cost Estimation</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "4" && (
                <li
                  className={
                    location.pathname === PATH.RESOURCE_DASHBOARD ||
                    location.pathname.includes("/resource-tasks")
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.RESOURCE_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <HiUsers className="" />
                    <span>Dashboard</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "4" && (
                <li
                  className={
                    location.pathname === PATH.USER ||
                    location.pathname === PATH.GENERATE_BLOG ||
                    location.pathname.includes("/resource-tasks")
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.USER,
                    }}
                    className=""
                  >
                    {" "}
                    <HiUsers className="" />
                    <span>Resources</span>
                  </Link>
                </li>
              )}
              {/* {user?.loginUser?.roleId === "1" && (
                <li
                  className={
                    location.pathname === PATH.BLOG_REQUEST || location.pathname.includes("/resource-tasks") ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.BLOG_REQUEST,
                    }}
                    className=""
                  >
                    {" "}
                    <FaBloggerB className="" />
                    <span>Total Resources</span>
                  </Link>
                </li>
              )} */}
              {user?.loginUser?.roleId === "4" && (
                <li
                  className={location.pathname === PATH.MY_BLOG ? "active" : ""}
                >
                  <Link
                    to={{
                      pathname: PATH.MY_BLOG,
                    }}
                    className=""
                  >
                    {" "}
                    <MdOutlineSpaceDashboard className="" />
                    <span>Projects</span>
                  </Link>
                </li>
              )}
              {/* {user?.loginUser?.roleId === "4" && (
                <li
                  className={
                    location.pathname === PATH.RESOURCE_DASHBOARD
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.RESOURCE_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Tasks List</span>
                  </Link>
                </li>
              )} */}
              {user?.loginUser?.roleId === "4" && (
                <li
                  className={
                    location.pathname === PATH.GENERATE_TEAM ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.GENERATE_TEAM,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Project Teams</span>
                  </Link>
                </li>
              )}

              {/* {user?.loginUser?.roleId === "2" && (
                <li
                  className={
                    location.pathname === PATH.USER_BLOG_REQUEST ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.USER_BLOG_REQUEST,
                    }}
                    className=""
                  >
                    {" "}
                    <FaBloggerB className="" />
                    <span>Blog Request</span>
                  </Link>
                </li>
              )} */}
              {user?.loginUser?.roleId === "2" && (
                <li
                  className={
                    location.pathname === PATH.TEAM_LEAD_DASHBOARD
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.TEAM_LEAD_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Dashboard</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "2" && (
                <li
                  className={
                    location.pathname === PATH.VIEW_RESOURCES_TASKS
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.VIEW_RESOURCES_TASKS,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Tasks</span>
                  </Link>
                </li>
              )}
              {user?.loginUser?.roleId === "2" && (
                <li
                  className={
                    location.pathname === PATH.USER_DASHBOARD ||
                    location.pathname.includes("/resource-tasks")
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.USER_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>My Team</span>
                  </Link>
                </li>
              )}

              {/* {user?.loginUser?.roleId === "2" && (
                <li
                  className={
                    location.pathname === PATH.RESOURCE_DASHBOARD
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.RESOURCE_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>My Tasks</span>
                  </Link>
                </li>
              )} */}
              {user?.loginUser?.roleId === "3" && (
                <li
                  className={
                    location.pathname === PATH.RESOURCE_DASHBOARD
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.RESOURCE_DASHBOARD,
                    }}
                    className=""
                  >
                    {" "}
                    <MdDashboardCustomize className="" />
                    <span>Dashboard</span>
                  </Link>
                </li>
              )}
            </>
          </ul>
        </div>
      </div>
    </div>
  );
}
