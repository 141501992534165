function request(actionType, allType) {
  return {
    type: actionType,
    payload: allType,
  };
}
function success(actionType, response) {
  return {
    type: actionType,
    payload: response,
  };
}
function failure(actionType, error) {
  return {
    type: actionType,
    payload: error,
  };
}
const USER = {
  GET_USER_LOADING: "GET_USER_LOADING",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  GET_USER_NO_LOGIN_ID_LOADING: "GET_USER_NO_LOGIN_ID_LOADING",
  GET_USER_NO_LOGIN_ID_SUCCESS: "GET_USER_NO_LOGIN_ID_SUCCESS",
  GET_USER_NO_LOGIN_ID_FAILURE: "GET_USER_NO_LOGIN_ID_FAILURE",

  DELETE_REQUEST_LOADING: "DELETE_REQUEST_LOADING",
  DELETE_REQUEST_SUCCESS: "DELETE_REQUEST_SUCCESS",
  DELETE_REQUEST_FAILURE: "DELETE_REQUEST_FAILURE", 

  DELETE_TASK_LOADING: "DELETE_TASK_LOADING",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE", 
  
  LOGIN_USER_LOADING: " LOGIN_USER_LOADING",
  LOGIN_USER_SUCCESS: " LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: " LOGIN_USER_FAILURE",
  
  ADD_USER_LOADING: "ADD_USER_LOADING",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  EDIT_USER_LOADING: "EDIT_USER_LOADING",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

  EDIT_TEAM_LOADING: "EDIT_TEAM_LOADING",
  EDIT_TEAM_SUCCESS: "EDIT_TEAM_SUCCESS",
  EDIT_TEAM_FAILURE: "EDIT_TEAM_FAILURE",

  EDIT_TASK_LOADING: "EDIT_TASK_LOADING",
  EDIT_TASK_SUCCESS: "EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILURE: "EDIT_TASK_FAILURE",

  EDIT_PROJECT_LOADING: "EDIT_PROJECT_LOADING",
  EDIT_PROJECT_SUCCESS: "EDIT_PROJECT_SUCCESS",
  EDIT_PROJECT_FAILURE: "EDIT_PROJECT_FAILURE",

  EDIT_RESOURCE_SALLERY_LOADING: "EDIT_RESOURCE_SALLERY_LOADING",
  EDIT_RESOURCE_SALLERY_SUCCESS: "EDIT_RESOURCE_SALLERY_SUCCESS",
  EDIT_RESOURCE_SALLERY_FAILURE: "EDIT_RESOURCE_SALLERY_FAILURE",

  DELETE_RESOURCE_SALLERY_LOADING: "DELETE_RESOURCE_SALLERY_LOADING",
  DELETE_RESOURCE_SALLERY_SUCCESS: "DELETE_RESOURCE_SALLERY_SUCCESS",
  DELETE_RESOURCE_SALLERY_FAILURE: "DELETE_RESOURCE_SALLERY_FAILURE",

  DELETE_TEAM_LOADING: "DELETE_TEAM_LOADING",
  DELETE_TEAM_SUCCESS: "DELETE_TEAM_SUCCESS",
  DELETE_TEAM_FAILURE: "DELETE_TEAM_FAILURE",

  DELETE_PROJECT_LOADING: "DELETE_PROJECT_LOADING",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",

  ARCHIVE_USER_LOADING: "ARCHIVE_USER_LOADING",
  ARCHIVE_USER_SUCCESS: "ARCHIVE_USER_SUCCESS",
  ARCHIVE_USER_FAILURE: "ARCHIVE_USER_FAILURE",

  DELETE_USER_LOADING: "DELETE_USER_LOADING",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  GET_BLOGS_BY_USERID_LOADING: " GET_BLOGS_BY_USERID_LOADING",
  GET_BLOGS_BY_USERID_SUCCESS: " GET_BLOGS_BY_USERID_SUCCESS",
  GET_BLOGS_BY_USERID_FAILURE: " GET_BLOGS_BY_USERID_FAILURE",

  GENERATE_BLOG_REQUEST_LOADING: " GENERATE_BLOG_REQUEST_LOADING",
  GENERATE_BLOG_REQUEST_SUCCESS: " GENERATE_BLOG_REQUEST_SUCCESS",
  GENERATE_BLOG_REQUEST_FAILURE: " GENERATE_BLOG_REQUEST_FAILURE",

  GET_ALL_BLOG_REQUEST_LOADING: " GET_ALL_BLOG_REQUEST_LOADING",
  GET_ALL_BLOG_REQUEST_SUCCESS: " GET_ALL_BLOG_REQUEST_SUCCESS",
  GET_ALL_BLOG_REQUEST_FAILURE: " GET_ALL_BLOG_REQUEST_FAILURE",

  CHANGE_REQUESTED_BLOG_STATUS_LOADING: " CHANGE_REQUESTED_BLOG_STATUS_LOADING",
  CHANGE_REQUESTED_BLOG_STATUS_SUCCESS: " CHANGE_REQUESTED_BLOG_STATUS_SUCCESS",
  CHANGE_REQUESTED_BLOG_STATUS_FAILURE: " CHANGE_REQUESTED_BLOG_STATUS_FAILURE",
  
  GET_BLOG_REQUEST_BY_USER_LOADING: " GET_BLOG_REQUEST_BY_USER_LOADING",
  GET_BLOG_REQUEST_BY_USER_SUCCESS: " GET_BLOG_REQUEST_BY_USER_SUCCESS",
  GET_BLOG_REQUEST_BY_USER_FAILURE: " GET_BLOG_REQUEST_BY_USER_FAILURE",

  GET_TASK_BY_PROJECT_MANAGER_LOADING: " GET_TASK_BY_PROJECT_MANAGER_LOADING",
  GET_TASK_BY_PROJECT_MANAGER_SUCCESS: " GET_TASK_BY_PROJECT_MANAGER_SUCCESS",
  GET_TASK_BY_PROJECT_MANAGER_FAILURE: " GET_TASK_BY_PROJECT_MANAGER_FAILURE",

  CREATE_PROJECT_LOADING: " CREATE_PROJECT_LOADING",
  CREATE_PROJECT_SUCCESS: " CREATE_PROJECT_SUCCESS",
  CREATE_PROJECT_FAILURE: " CREATE_PROJECT_FAILURE",

  CREATE_TEAM_LOADING: " CREATE_TEAM_LOADING",
  CREATE_TEAM_SUCCESS: " CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE: " CREATE_TEAM_FAILURE",

  GET_ALL_PROJECTS_LOADING: " GET_ALL_PROJECTS_LOADING",
  GET_ALL_PROJECTS_SUCCESS: " GET_ALL_PROJECTS_SUCCESS",
  GET_ALL_PROJECTS_FAILURE: " GET_ALL_PROJECTS_FAILURE",

  GET_ALL_TEAMS_BY_LOGIN_ID_LOADING: " GET_ALL_TEAMS_BY_LOGIN_ID_LOADING",
  GET_ALL_TEAMS_BY_LOGIN_ID_SUCCESS: " GET_ALL_TEAMS_BY_LOGIN_ID_SUCCESS",
  GET_ALL_TEAMS_BY_LOGIN_ID_FAILURE: " GET_ALL_TEAMS_BY_LOGIN_ID_FAILURE",

  GET_ALL_TEAMS_LOADING: " GET_ALL_TEAMS_LOADING",
  GET_ALL_TEAMS_SUCCESS: " GET_ALL_TEAMS_SUCCESS",
  GET_ALL_TEAMS_FAILURE: " GET_ALL_TEAMS_FAILURE",

  GET_ALL_SALLERY_RESOURCES_LOADING: " GET_ALL_SALLERY_RESOURCES_LOADING",
  GET_ALL_SALLERY_RESOURCES_SUCCESS: " GET_ALL_SALLERY_RESOURCES_SUCCESS",
  GET_ALL_SALLERY_RESOURCES_FAILURE: " GET_ALL_SALLERY_RESOURCES_FAILURE",

  GET_PROJECT_PROGRESS_DATA_LOADING: " GET_PROJECT_PROGRESS_DATA_LOADING",
  GET_PROJECT_PROGRESS_DATA_SUCCESS: " GET_PROJECT_PROGRESS_DATA_SUCCESS",
  GET_PROJECT_PROGRESS_DATA_FAILURE: " GET_PROJECT_PROGRESS_DATA_FAILURE",

  GET_TEAM_LEAD_RESOURCES_TASKS_LOADING: " GET_TEAM_LEAD_RESOURCES_TASKS_LOADING",
  GET_TEAM_LEAD_RESOURCES_TASKS_SUCCESS: " GET_TEAM_LEAD_RESOURCES_TASKS_SUCCESS",
  GET_TEAM_LEAD_RESOURCES_TASKS_FAILURE: " GET_TEAM_LEAD_RESOURCES_TASKS_FAILURE",

 CREATE_SALLERY_RESOURCE_LOADING: "CREATE_SALLERY_RESOURCE_LOADING",
 CREATE_SALLERY_RESOURCE_SUCCESS: "CREATE_SALLERY_RESOURCE_SUCCESS",
 CREATE_SALLERY_RESOURCE_FAILURE: "CREATE_SALLERY_RESOURCE_FAILURE",
 
 GET_ALL_RESOURCE_DATA_RESOURCE_LOADING: "GET_ALL_RESOURCE_DATA_RESOURCE_LOADING",
 GET_ALL_RESOURCE_DATA_RESOURCE_SUCCESS: "GET_ALL_RESOURCE_DATA_RESOURCE_SUCCESS",
 GET_ALL_RESOURCE_DATA_RESOURCE_FAILURE: "GET_ALL_RESOURCE_DATA_RESOURCE_FAILURE",

  GET_DATA_BY_DECREPTED_KEY_LOADING: "GET_DATA_BY_DECREPTED_KEY_LOADING",
 GET_DATA_BY_DECREPTED_KEY_SUCCESS: "GET_DATA_BY_DECREPTED_KEY_SUCCESS",
 GET_DATA_BY_DECREPTED_KEY_FAILURE: "GET_DATA_BY_DECREPTED_KEY_FAILURE",
};

const GENERATEBLOG = {
  GENERATE_BLOG_LOADING: " GENERATE_BLOG_LOADING",
  GENERATE_BLOG_SUCCESS: " GENERATE_BLOG_SUCCESS",
  GENERATE_BLOG_FAILURE: " GENERATE_BLOG_FAILURE",
};

const BLOGS = {
  REQUEST_BLOG_LOADING: " REQUEST_BLOG_LOADING",
  REQUEST_BLOG_SUCCESS: " REQUEST_BLOG_SUCCESS",
  REQUEST_BLOG_FAILURE: " REQUEST_BLOG_FAILURE",

  GET_ALL_BLOG_LOADING: " GET_ALL_BLOG_LOADING",
  GET_ALL_BLOG_SUCCESS: " GET_ALL_BLOG_SUCCESS",
  GET_ALL_BLOG_FAILURE: " GET_ALL_BLOG_FAILURE",

  GET_REQUESTED_BLOG_LOADING: " GET_REQUESTED_BLOG_LOADING",
  GET_REQUESTED_BLOG_SUCCESS: " GET_REQUESTED_BLOG_SUCCESS",
  GET_REQUESTED_BLOG_FAILURE: " GET_REQUESTED_BLOG_FAILURE",
};

export { request, success, failure, USER, GENERATEBLOG, BLOGS };
